import {
  FETCH_STATS,
  FETCH_SIDE,
  FETCH_CHAR,
  FETCH_MASTER,
  FETCH_INV,
  FETCH_EQP,
  UPDATE_PLAYER_CREDITS,
} from "../constants/actionTypes";

const initialState = {
  fetching: false,
  fetched: false,
  char: {},
  skills: {},
  side: {},
  master: {},
  inv: { quest: 0 },
  eqp: { quest: 0 },
  error: null,
};

const skills = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STATS:
      return {
        ...state,
        fetched: true,
        skills: action.payload,
      };
    case FETCH_SIDE:
      return {
        ...state,
        fetched: true,
        side: action.payload,
      };
    case FETCH_CHAR:
      return {
        ...state,
        fetched: true,
        char: action.payload,
      };
    case FETCH_MASTER:
      return {
        ...state,
        fetched: true,
        master: action.payload,
      };
    case FETCH_INV:
      return {
        ...state,
        fetched: true,
        inv: action.payload,
      };
    case FETCH_EQP:
      return {
        ...state,
        fetched: true,
        eqp: action.payload,
      };
    case UPDATE_PLAYER_CREDITS:
      return {
        ...state,
        skills: {
          ...state.skills,
          char: {
            ...state.skills.char,
            cash: action.payload,
          },
        },
      };
    default:
      return state;
  }
};

export default skills;
