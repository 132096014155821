import React from "react";
import {
  IS_AUTH,
  NEW_MESSAGE,
  NEW_REPORT,
  NEW_TRADE,
} from "../constants/actionTypes";

const initalToken = sessionStorage.getItem("jwt");
const initialName = sessionStorage.getItem("username");
const initialId = sessionStorage.getItem("userId");

const initialState = {
  isAuth: initalToken ? true : false,
  username: initialName,
  userId: +initialId,
  newMessage: false,
  newTrade: false,
  newReport: false,
};

const User = (state = initialState, action) => {
  switch (action.type) {
    case IS_AUTH:
      return {
        ...state,
        isAuth: action.payload.isAuth,
        userId: action.payload.userId,
        username: action.payload.username,
      };
    case NEW_MESSAGE:
      return {
        ...state,
        newMessage: action.payload,
      };
    case NEW_TRADE:
      return {
        ...state,
        newTrade: action.payload,
      };
    case NEW_REPORT:
      return {
        ...state,
        newReport: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default User;
