import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import "./city.module.css";
import { GET } from "../../tools/fetch";
import { useSelector } from "react-redux";
const City2 = () => {
  const history = useHistory();
  const [map, setMap] = useState(false);
  const [reiseStart, setReiseStart] = useState(false);
  const [error, setError] = useState("");
  const userId = useSelector((state) => state.user.userId);

  const startExplore = async () => {
    const response = await GET("/character/startexplore");
    if (response.response === true) {
      history.push("/explore");
    } else if (response.response === false) {
      setError("Du bist nicht bereit.");
    }
  };

  return (
    <div>
      <div id="pic" className="container-fluid p-0 m-0 position-relative">
        <img
          className="w-100"
          style={{ verticalAlign: "sub" }}
          src={require(`../../images/city/dorf1_1.jpg`)}
          alt=""
        />
        {/*der weg in den versteckten layer*/}
        <div
          className="position-absolute"
          style={{ top: "50%", left: "88%", width: "3%", height: "13%" }}
          onClick={() => history.push("/layer2")}
        />
        {/*der weg in die Bar*/}
        <div
          className="position-absolute"
          style={{ top: "50%", left: "57%", width: "27%", height: "33%" }}
          onClick={() => history.push("/bar")}
        />
        {/*der weg nach draußen*/}
        <div
          className="position-absolute"
          style={{
            top: "26%",
            left: "16%",
            width: "11%",
            height: "30%",
            cursor: "pointer",
          }}
          onClick={() => setMap(true)}
        />
        {/*der weg zum shop*/}
        <div
          className="position-absolute"
          style={{
            top: "40%",
            left: "38%",
            width: "11%",
            height: "34%",
          }}
          onClick={() => history.push("/shop2")}
        />
      </div>
      <Modal
        style={{ maxWidth: "inherit" }}
        centered
        show={map}
        onHide={() => setMap(false)}
        backdrop
      >
        <Modal.Body className="p-1">
          <img
            src={require("../../images/city/outside.png")}
            className="w-100"
          />
          <div
            style={{
              top: "50%",
              left: "50%",
              width: "10%",
              height: "10%",
              cursor: "pointer",
            }}
            className="position-absolute border border-danger"
            onClick={() => history.push("/temple")}
          ></div>
        </Modal.Body>
        <Modal.Footer className="pt-0 pb-1 d-flex justify-content-center">
          {!reiseStart && (
            <Button
              onClick={() => {
                setReiseStart(true);
                setError("");
              }}
              variant="outline-secondary"
            >
              erkunden
            </Button>
          )}
          {reiseStart && (
            <div className="d-block">
              <div>
                Das Erkunden der Außenbezirke ist sehr gefährlich und dauert
                mehrere Stunden. Außer deiner Tränke steht dir nichts aus deinem
                Lager zur Verfügung.
              </div>
              <div className="d-flex justify-content-center">
                <Button onClick={startExplore} variant="outline-success">
                  starten
                </Button>{" "}
                <Button
                  onClick={() => setReiseStart(false)}
                  variant="outline-danger"
                >
                  verweilen
                </Button>
              </div>
              {error}
            </div>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default City2;
