import React, { useState, useEffect, useRef, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { GET, POST } from "../tools/fetch";
import Item from "./item";
import Search from "./search";
import CheckQuest from "../components/quest/checkQuest";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Spinner from "react-bootstrap/Spinner";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Badge from "react-bootstrap/Badge";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Pagination from "react-bootstrap/Pagination";
import {
  inventoryState__setEquipment,
  inventoryState__setItems,
} from "../redux/actions/inventoryActions";
import { Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import ItemCardInventory from "./item/itemCardInventory";

const Inventory = () => {
  const history = useHistory();

  const [loadingItems, setLoadingItems] = useState(true);
  const [loadingEquip, setLoadingEquip] = useState(true);

  const [activePage, setActivePage] = useState(1);
  const [itemType, setItemType] = useState("weapons");
  const [sortDir, setSortDir] = useState("desc");
  const [sortType, setSortType] = useState("itemid");
  const [searchVal, setSearchVal] = useState("");
  const [viewMode, setViewMode] = useState("grid"); // grid or list view
  const [itemsPerPage, setItemsPerPage] = useState(12);
  const [initialLoad, setInitialLoad] = useState(true);

  const dispatch = useDispatch();
  const inv = useSelector((state) => state.skills.inv);
  const eqp = useSelector((state) => state.skills.eqp);
  const quest = useSelector((state) => state.skills.inv.quest);

  const loadEquip = async () => {
    try {
      setLoadingEquip(true);
      const response = await GET(
        `/character/inventory?id=${itemType}&page=${activePage}&sort=${sortType}&direction=${sortDir}&search=${searchVal}`
      );
      if (response) {
        dispatch(inventoryState__setEquipment(response));
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingEquip(false);
    }
  };

  const dropItem = async (item) => {
    //send request to DB
    const request = await GET(
      `/character/inventory/dequip/${item.type}/${item.itemid}`
    );

    //get new State
    if (request) {
      try {
        setLoadingEquip(true);
        setLoadingItems(true);
        
        // Get updated data after dequip
        const response = await GET(
          `/character/inventory?id=${itemType}&page=${activePage}&sort=${sortType}&direction=${sortDir}&search=${searchVal}`
        );
        
        if (response) {
          // Update both equipped items and inventory items
          dispatch(inventoryState__setEquipment(response));
          dispatch(inventoryState__setItems(response));
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoadingEquip(false);
        setLoadingItems(false);
      }
    }
  };

  const equipItem = async (item) => {
    //send request to DB
    const request = await GET(
      `/character/inventory/equip/${item.type}/${item.itemid}`
    );

    //get new State
    if (request) {
      try {
        setLoadingEquip(true);
        setLoadingItems(true);
        
        // Get updated data after equip
        const response = await GET(
          `/character/inventory?id=${itemType}&page=${activePage}&sort=${sortType}&direction=${sortDir}&search=${searchVal}`
        );
        
        if (response) {
          // Update both equipped items and inventory items
          dispatch(inventoryState__setEquipment(response));
          dispatch(inventoryState__setItems(response));
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoadingEquip(false);
        setLoadingItems(false);
      }
    }
  };

  // This function now only loads items when necessary (initial load, type/sort/search changes)
  // It no longer handles pagination directly, as we use the local filtering for that
  const loadItems = async (
    activePage,
    itemType,
    sortDir,
    sortType,
    searchVal
  ) => {
    try {
      setLoadingItems(true);
      const response = await GET(
        `/character/inventory?id=${itemType}&page=1&sort=${sortType}&direction=${sortDir}&search=${searchVal}`
      );
      if (response) {
        dispatch(inventoryState__setItems(response));
        setActivePage(1); // Reset to first page when data changes
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingItems(false);
    }
  };

  // Pagination now just updates the page state without API call
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleOnSortDir = (SortType) => {
    if (SortType === sortType) {
      if (sortDir === "desc") {
        setSortDir("asc");
      } else {
        setSortDir("desc");
      }
    }
    setSortType(SortType);
  };

  const onSearch = (event) => {
    setSearchVal(event.target.value);
  };

  const clearSearch = () => {
    setSearchVal("");
  };

  const redirectQuest1 = () => {
    history.push("/arena");
  };

  const [ItemModal, setItemModal] = useState(false);
  const [consumedItem, setConsumedItem] = useState({ img: "executivecase1" });
  const [rewardedItem, setRewardedItem] = useState({});

  const consumeItem = async (i) => {
    //Box-Item
    if (i.sizex === 1) {
      let lootItem = "";
      switch (i.name) {
        case "Rancor-Lootbox (S)":
          lootItem = "ranc4";
          break;
        case "Rancor-Lootbox (XL)":
          lootItem = "ranc7";
          break;
        default:
          break;
      }
      const item = await POST("/events/item", {
        item: lootItem,
        boxId: i.itemid,
      });
      if (item) {
        setRewardedItem(item.loot);
      }
      loadEquip();
    } else if (i.img === "health") {
      await POST("/character/saveuser", {
        where: "char",
        what: "health",
        amount: 50,
        item: i.itemid,
      });
      loadEquip();
      setItemModal(false);
    } else if (i.img === "mana") {
      await POST("/character/saveuser", {
        where: "char",
        what: "mana",
        amount: 70,
        item: i.itemid,
      });
      loadEquip();
      setItemModal(false);
    } else if (i.img === "energie") {
      await POST("/character/saveuser", {
        where: "char",
        what: "energy",
        amount: 10,
        item: i.itemid,
      });
      loadEquip();
      setItemModal(false);
    } else if (i.img === "mega") {
      await POST("/character/saveuser", {
        where: "char",
        what: "mega",
        amount: 50,
        item: i.itemid,
      });
      loadEquip();
      setItemModal(false);
    }
  };

  // Process and filter items from Redux store instead of relying on API pagination
  const processedItems = useMemo(() => {
    if (!inv.items || inv.items.length === 0) return [];
    
    let filtered = [...inv.items];
    
    // Apply local search/filter if needed
    if (searchVal) {
      filtered = filtered.filter(item => 
        item.name.toLowerCase().includes(searchVal.toLowerCase())
      );
    }
    
    // Apply local sorting
    filtered.sort((a, b) => {
      let valueA = a[sortType];
      let valueB = b[sortType];
      
      // Handle string vs number comparison
      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return sortDir === 'asc' 
          ? valueA.localeCompare(valueB) 
          : valueB.localeCompare(valueA);
      } else {
        // Convert to numbers for numeric comparison
        valueA = Number(valueA) || 0;
        valueB = Number(valueB) || 0;
        return sortDir === 'asc' ? valueA - valueB : valueB - valueA;
      }
    });
    
    return filtered;
  }, [inv.items, searchVal, sortType, sortDir]);

  // Calculate displayed items for current page
  const paginatedItems = useMemo(() => {
    const startIndex = (activePage - 1) * itemsPerPage;
    return processedItems.slice(startIndex, startIndex + itemsPerPage);
  }, [processedItems, activePage, itemsPerPage]);

  // Calculate total items and pages
  const totalItems = processedItems.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // Get category counts for item types
  const getCategoryCount = (type) => {
    if (!inv.itemCounts) return 0;
    return inv.itemCounts?.[type] || 0;
  };

  // Handle click on item category - this still needs API call as it changes item type
  const handleCategoryClick = (type) => {
    loadItems(1, type, "desc", "itemid", "");
    setItemType(type);
    setActivePage(1);
    setSortType("itemid");
    setSortDir("desc");
    setSearchVal("");
  };

  // Create pagination items
  const renderPaginationItems = () => {
    const items = [];
    const maxVisiblePages = window.innerWidth < 768 ? 3 : 5; // Fewer pages on mobile
    
    if (totalPages <= maxVisiblePages) {
      // Show all pages if there are less than maxVisiblePages
      for (let page = 1; page <= totalPages; page++) {
        items.push(
          <Pagination.Item 
            key={page} 
            active={page === activePage}
            onClick={() => handlePageChange(page)}
          >
            {page}
          </Pagination.Item>
        );
      }
    } else {
      // Always show first page
      items.push(
        <Pagination.Item 
          key={1} 
          active={1 === activePage}
          onClick={() => handlePageChange(1)}
        >
          1
        </Pagination.Item>
      );
      
      // Calculate range of pages to show
      let startPage = Math.max(2, activePage - Math.floor(maxVisiblePages / 2) + 1);
      let endPage = Math.min(totalPages - 1, startPage + maxVisiblePages - 3);
      
      // Adjust if we're near the start
      if (activePage <= Math.floor(maxVisiblePages / 2) + 1) {
        endPage = Math.min(maxVisiblePages - 1, totalPages - 1);
        startPage = 2;
      }
      
      // Adjust if we're near the end
      if (activePage >= totalPages - Math.floor(maxVisiblePages / 2)) {
        startPage = Math.max(2, totalPages - (maxVisiblePages - 1));
        endPage = totalPages - 1;
      }
      
      // Add ellipsis if needed
      if (startPage > 2) {
        items.push(<Pagination.Ellipsis key="ellipsis1" />);
      }
      
      // Add middle pages
      for (let page = startPage; page <= endPage; page++) {
        items.push(
          <Pagination.Item 
            key={page} 
            active={page === activePage}
            onClick={() => handlePageChange(page)}
          >
            {page}
          </Pagination.Item>
        );
      }
      
      // Add ellipsis if needed
      if (endPage < totalPages - 1) {
        items.push(<Pagination.Ellipsis key="ellipsis2" />);
      }
      
      // Always show last page
      if (totalPages > 1) {
        items.push(
          <Pagination.Item 
            key={totalPages} 
            active={totalPages === activePage}
            onClick={() => handlePageChange(totalPages)}
          >
            {totalPages}
          </Pagination.Item>
        );
      }
    }
    
    return items;
  };

  // Only fetch data when necessary params change
  useEffect(() => {
    if (initialLoad || sortDir !== 'desc' || sortType !== 'itemid' || searchVal !== '') {
      loadItems(activePage, itemType, sortDir, sortType, searchVal);
      setInitialLoad(false);
    }
  }, [sortDir, sortType, searchVal, itemType]);

  useEffect(() => {
    loadEquip();
  }, []);

  return (
    <div className="inventory-container">
      {loadingItems === false && loadingEquip === false && quest[0] === 1 && (
        <div className="mb-3">
          <CheckQuest
            details={quest[1]}
            refresh={
              quest[1].quest_id === "1"
                ? redirectQuest1
                : () => loadItems(activePage, itemType, sortDir, sortType, searchVal)
            }
          />
        </div>
      )}
      
      {loadingEquip === false && inv.error ? (
        <Row className="message error mb-3">{inv.error}</Row>
      ) : null}

      {/* Equipment section */}
      {loadingEquip ? (
        <div className="d-flex justify-content-center p-4">
          <Spinner animation="border" />
        </div>
      ) : (
        <div className="equipment-section mb-4">
          <h5 className="section-title mb-3">Ausgerüstete Items</h5>
          <Row>
            <Col md={3} sm={6} className="mb-3">
              <Card className="equipment-card h-100">
                <Card.Header className="d-flex justify-content-between align-items-center">
                  <span>Waffe</span>
                  {eqp.act_weapon && (
                    <Badge bg="dark" pill>
                      {eqp.act_weapon.qlvl}
                    </Badge>
                  )}
                </Card.Header>
                <Card.Body>
                  {eqp.act_weapon ? (
                    <Item
                      item={eqp.act_weapon}
                      imgFolder="weapons"
                      type="weapon"
                      dropItem={dropItem}
                    />
                  ) : (
                    <div className="empty-slot">
                      <p className="text-center text-muted">Keine Waffe ausgerüstet</p>
                    </div>
                  )}
                </Card.Body>
              </Card>
            </Col>
            
            <Col md={3} sm={6} className="mb-3">
              <Card className="equipment-card h-100">
                <Card.Header className="d-flex justify-content-between align-items-center">
                  <span>Ring 1</span>
                  {eqp.act_jewelry1 && (
                    <Badge bg="dark" pill>
                      {eqp.act_jewelry1.qlvl}
                    </Badge>
                  )}
                </Card.Header>
                <Card.Body>
                  {eqp.act_jewelry1 ? (
                    <Item
                      item={eqp.act_jewelry1}
                      imgFolder="rings"
                      type="ring1"
                      dropItem={dropItem}
                    />
                  ) : (
                    <div className="empty-slot">
                      <p className="text-center text-muted">Kein Ring ausgerüstet</p>
                    </div>
                  )}
                </Card.Body>
              </Card>
            </Col>
            
            <Col md={3} sm={6} className="mb-3">
              <Card className="equipment-card h-100">
                <Card.Header className="d-flex justify-content-between align-items-center">
                  <span>Ring 2</span>
                  {eqp.act_jewelry2 && (
                    <Badge bg="dark" pill>
                      {eqp.act_jewelry2.qlvl}
                    </Badge>
                  )}
                </Card.Header>
                <Card.Body>
                  {eqp.act_jewelry2 ? (
                    <Item
                      item={eqp.act_jewelry2}
                      imgFolder="rings"
                      type="ring2"
                      dropItem={dropItem}
                    />
                  ) : (
                    <div className="empty-slot">
                      <p className="text-center text-muted">Kein Ring ausgerüstet</p>
                    </div>
                  )}
                </Card.Body>
              </Card>
            </Col>
            
            <Col md={3} sm={6} className="mb-3">
              <Card className="credits-card h-100">
                <Card.Header className="d-flex justify-content-between align-items-center">
                  <span>Credits</span>
                </Card.Header>
                <Card.Body>
                  <Item item={eqp.char} imgFolder="misc" type="cash" />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      )}
      
      <hr className="inventory-divider my-4" />
      
      {/* Inventory section */}
      <div className="inventory-section">
        <h5 className="section-title mb-3">Inventar</h5>
        {(quest === 0 || (quest[1]?.quest_id === "1" && quest[1]?.step_id === "2")) && (
          <>
            <div className="inventory-controls mb-3">
              {/* Category filter */}
              <div className="category-filters mb-3">
                <h6 className="text-muted mb-2">Kategorie</h6>
                <Row>
                  <Col>
                    <div className="category-buttons d-flex flex-wrap">
                      <Button
                        variant={itemType === "weapons" ? "dark" : "outline-dark"}
                        className="category-btn me-2 mb-2"
                        onClick={() => handleCategoryClick("weapons")}
                      >
                        Waffen
                        <Badge bg={itemType === "weapons" ? "light" : "secondary"} text={itemType === "weapons" ? "dark" : "light"} className="ms-1">
                          {getCategoryCount("weapons")}
                        </Badge>
                      </Button>
                      <Button
                        variant={itemType === "rings" ? "dark" : "outline-dark"}
                        className="category-btn me-2 mb-2"
                        onClick={() => handleCategoryClick("rings")}
                      >
                        Ringe
                        <Badge bg={itemType === "rings" ? "light" : "secondary"} text={itemType === "rings" ? "dark" : "light"} className="ms-1">
                          {getCategoryCount("rings")}
                        </Badge>
                      </Button>
                      <Button
                        variant={itemType === "misc" ? "dark" : "outline-dark"}
                        className="category-btn me-2 mb-2"
                        onClick={() => handleCategoryClick("misc")}
                      >
                        Verschiedenes
                        <Badge bg={itemType === "misc" ? "light" : "secondary"} text={itemType === "misc" ? "dark" : "light"} className="ms-1">
                          {getCategoryCount("misc")}
                        </Badge>
                      </Button>
                      <Button
                        variant={itemType === "bots" ? "dark" : "outline-dark"}
                        className="category-btn me-2 mb-2"
                        onClick={() => handleCategoryClick("bots")}
                      >
                        Drohnen
                        <Badge bg={itemType === "bots" ? "light" : "secondary"} text={itemType === "bots" ? "dark" : "light"} className="ms-1">
                          {getCategoryCount("bots")}
                        </Badge>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>

              {/* Search and view controls */}
              <Row className="mb-3">
                <Col md={8} className="mb-2 mb-md-0">
                  <InputGroup>
                    <Form.Control
                      type="text"
                      placeholder="Suchen..."
                      value={searchVal}
                      onChange={onSearch}
                    />
                    {searchVal && (
                      <Button variant="outline-secondary" onClick={clearSearch}>
                        &times;
                      </Button>
                    )}
                    <InputGroup.Text>
                      <span role="img" aria-label="search">🔍</span>
                    </InputGroup.Text>
                  </InputGroup>
                </Col>
                <Col md={4} className="d-flex justify-content-end">
                  <ButtonGroup>
                    <Button
                      variant={viewMode === "grid" ? "primary" : "outline-primary"}
                      onClick={() => setViewMode("grid")}
                      title="Rasteransicht"
                    >
                      <span role="img" aria-label="grid">
                        ▦
                      </span>
                    </Button>
                    <Button
                      variant={viewMode === "list" ? "primary" : "outline-primary"}
                      onClick={() => setViewMode("list")}
                      title="Listenansicht"
                    >
                      <span role="img" aria-label="list">
                        ≡
                      </span>
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>

              {/* Sorting options */}
              <Row className="mb-3">
                <Col>
                  <h6 className="text-muted mb-2">Sortieren nach</h6>
                  <div className="sort-options d-flex flex-wrap">
                    {itemType === "weapons" && (
                      <>
                        <Button
                          size="sm" 
                          variant={sortType === "mindmg" ? "dark" : "outline-dark"}
                          onClick={() => handleOnSortDir("mindmg")}
                          className="me-1 mb-2"
                        >
                          Min. Schaden {sortType === "mindmg" && (sortDir === "desc" ? "↓" : "↑")}
                        </Button>
                        <Button
                          size="sm"
                          variant={sortType === "maxdmg" ? "dark" : "outline-dark"}
                          onClick={() => handleOnSortDir("maxdmg")}
                          className="me-1 mb-2"
                        >
                          Max. Schaden {sortType === "maxdmg" && (sortDir === "desc" ? "↓" : "↑")}
                        </Button>
                      </>
                    )}
                    <Button
                      size="sm"
                      variant={sortType === "price" ? "dark" : "outline-dark"}
                      onClick={() => handleOnSortDir("price")}
                      className="me-1 mb-2"
                    >
                      Wert {sortType === "price" && (sortDir === "desc" ? "↓" : "↑")}
                    </Button>
                    <Button
                      size="sm"
                      variant={sortType === "qlvl" ? "dark" : "outline-dark"}
                      onClick={() => handleOnSortDir("qlvl")}
                      className="me-1 mb-2"
                    >
                      Qualität {sortType === "qlvl" && (sortDir === "desc" ? "↓" : "↑")}
                    </Button>
                    {itemType !== "misc" && (
                      <>
                        <Button
                          size="sm"
                          variant={sortType === "reql" ? "dark" : "outline-dark"}
                          onClick={() => handleOnSortDir("reql")}
                          className="me-1 mb-2"
                        >
                          Min. Level {sortType === "reql" && (sortDir === "desc" ? "↓" : "↑")}
                        </Button>
                        <Button
                          size="sm"
                          variant={sortType === "reqs" ? "dark" : "outline-dark"}
                          onClick={() => handleOnSortDir("reqs")}
                          className="me-1 mb-2"
                        >
                          Min. Skill {sortType === "reqs" && (sortDir === "desc" ? "↓" : "↑")}
                        </Button>
                      </>
                    )}
                  </div>
                </Col>
              </Row>
            </div>

            {loadingItems ? (
              <div className="d-flex justify-content-center p-5">
                <Spinner animation="border" />
              </div>
            ) : processedItems.length > 0 ? (
              <>
                {/* Grid View */}
                {viewMode === "grid" ? (
                  <Row className="inventory-grid mb-4">
                    {paginatedItems.map((item) => {
                      item.type = inv.img;
                      return (
                        <Col key={item.itemid} xs={6} sm={4} md={3} lg={2} className="mb-3">
                          <ItemCardInventory
                            item={item}
                            imgFolder={inv.img}
                            type="inventory"
                            itemModal={setItemModal}
                            consumItem={setConsumedItem}
                            equipItem={equipItem}
                            cMana={inv.cMana}
                            cMega={inv.cMega}
                            cHealth={inv.cHealth}
                            cEnergie={inv.cEnergie}
                            cRancS={inv.cRancS}
                          />
                        </Col>
                      );
                    })}
                  </Row>
                ) : (
                  /* List View */
                  <div className="inventory-list mb-4">
                    <Card>
                      <Card.Header className="list-header">
                        <Row>
                          <Col xs={6}>Item</Col>
                          <Col xs={3}>Details</Col>
                          <Col xs={3} className="text-end">Aktion</Col>
                        </Row>
                      </Card.Header>
                      <Card.Body className="p-0">
                        {paginatedItems.map((item) => {
                          item.type = inv.img;
                          
                          // Determine image file extension
                          let imgEnd = "jpg";
                          if ((inv.img === "misc" && 
                              item.img !== "executivecase1" && 
                              item.img !== "cell") || 
                              inv.img === "bots") {
                            imgEnd = "png";
                          }
                          
                          return (
                            <div key={item.itemid} className="list-item p-2 border-bottom">
                              <Row className="align-items-center">
                                <Col xs={6} className="d-flex align-items-center">
                                  <div className="item-image me-2">
                                    <img 
                                      src={require(`../images/items/${inv.img}/${item.img}.${imgEnd}`)} 
                                      alt={item.name} 
                                      style={{ width: '50px', height: '50px', objectFit: 'contain' }}
                                    />
                                  </div>
                                  <div className="item-info">
                                    <div className="item-name fw-bold">{item.name}</div>
                                    <div className="item-badges">
                                      {item.uni === "y" && <Badge bg="warning" className="me-1">Unique</Badge>}
                                      {item.crafted === "y" && <Badge bg="info" className="me-1">Crafted</Badge>}
                                      {item.nodrop === "y" && <Badge bg="danger" className="me-1">No Drop</Badge>}
                                      <Badge bg="secondary">Qualität: {item.qlvl}</Badge>
                                    </div>
                                  </div>
                                </Col>
                                <Col xs={3}>
                                  <div className="item-stats small">
                                    {item.mindmg !== 0 && item.mindmg !== undefined && (
                                      <div>Schaden: {item.mindmg} - {item.maxdmg}</div>
                                    )}
                                    {item.stat1_stat && (
                                      <div>
                                        {item.stat1_stat} 
                                        {item.stat1_mod === "inc" ? " +" : 
                                         item.stat1_mod === "dec" ? " -" : " "} 
                                        {item.stat1_value !== "0" && item.stat1_value}
                                      </div>
                                    )}
                                    {item.reql > 0 && <div>Min. Level: {item.reql}</div>}
                                    {item.reqs > 0 && <div>Min. Skill: {item.reqs}</div>}
                                  </div>
                                </Col>
                                <Col xs={3} className="text-end">
                                  {item.consumable === true && inv.img === "misc" ? (
                                    <Button 
                                      variant="primary" 
                                      size="sm"
                                      onClick={() => {
                                        setItemModal(true);
                                        setConsumedItem(item);
                                      }}
                                    >
                                      Benutzen
                                    </Button>
                                  ) : (
                                    item.reql <= inv.char.skills.level &&
                                    item.reqs <= inv.char.skills.dex &&
                                    inv.img !== "misc" &&
                                    inv.img !== "bots" && (
                                      <Button 
                                        variant="success" 
                                        size="sm"
                                        onClick={() => equipItem(item)}
                                      >
                                        Ausrüsten
                                      </Button>
                                    )
                                  )}
                                </Col>
                              </Row>
                            </div>
                          );
                        })}
                      </Card.Body>
                    </Card>
                  </div>
                )}

                {/* Pagination */}
                {totalPages > 1 && (
                  <div id="pagination-section" className="pagination-container py-3 px-2 mb-4">
                    <div className="items-info small text-muted mb-2">
                      Zeige {(activePage - 1) * itemsPerPage + 1} - {Math.min(activePage * itemsPerPage, totalItems)} von {totalItems} Items
                    </div>
                    <Pagination size="sm" className="mb-0 flex-wrap justify-content-center">
                      <Pagination.First 
                        onClick={() => handlePageChange(1)} 
                        disabled={activePage === 1}
                      />
                      <Pagination.Prev 
                        onClick={() => handlePageChange(Math.max(1, activePage - 1))} 
                        disabled={activePage === 1}
                      />
                      
                      {renderPaginationItems()}
                      
                      <Pagination.Next 
                        onClick={() => handlePageChange(Math.min(totalPages, activePage + 1))} 
                        disabled={activePage === totalPages}
                      />
                      <Pagination.Last 
                        onClick={() => handlePageChange(totalPages)} 
                        disabled={activePage === totalPages}
                      />
                    </Pagination>
                  </div>
                )}
              </>
            ) : (
              <div className="empty-inventory text-center p-5">
                <div className="empty-icon mb-3">📦</div>
                <h4>Keine Items gefunden</h4>
                {searchVal ? (
                  <p>
                    Deine Suche nach "{searchVal}" ergab keine Treffer.
                    <Button variant="link" onClick={clearSearch}>
                      Suche zurücksetzen
                    </Button>
                  </p>
                ) : (
                  <p>Du hast keine {itemType === "weapons" ? "Waffen" : 
                                    itemType === "rings" ? "Ringe" : 
                                    itemType === "bots" ? "Drohnen" : 
                                    "Gegenstände"} in deinem Inventar.</p>
                )}
              </div>
            )}
          </>
        )}
      </div>

      {/* Item Use Modal */}
      <Modal
        show={ItemModal}
        onHide={() => (setItemModal(false), setRewardedItem({}))}
        centered
      >
        <ModalHeader closeButton>
          <ModalTitle>{consumedItem.name}</ModalTitle>
        </ModalHeader>
        <ModalBody className="text-center">
          {Object.keys(rewardedItem).length === 0 ? (
            <>
              <Card.Img
                variant="top"
                style={{ width: "120px", height: "120px", objectFit: "contain" }}
                className="mx-auto d-block my-3"
                src={
                  consumedItem.name !== "Health-Potion" &&
                  consumedItem.name !== "Mana-Potion" &&
                  consumedItem.name !== "Mega-Potion" &&
                  consumedItem.name !== "Energie-Potion"
                    ? require(`../images/items/misc/${consumedItem.img}.jpg`)
                    : require(`../images/items/misc/${consumedItem.img}.png`)
                }
              />
              {consumedItem.stat1_mod && consumedItem.stat1_stat && (
                <div className="item-effect mb-3">
                  {consumedItem.stat1_mod} {consumedItem.stat1_stat}
                </div>
              )}
              <p>Möchtest du diesen Gegenstand verwenden?</p>
            </>
          ) : (
            <>
              <div className="reward-notification mb-3">
                <div className="alert alert-success">
                  <h5 className="alert-heading">Belohnung erhalten!</h5>
                </div>
              </div>
              <Item
                item={rewardedItem}
                imgFolder={rewardedItem.type}
                type="box"
              />
            </>
          )}
        </ModalBody>
        <ModalFooter>
          {Object.keys(rewardedItem).length === 0 ? (
            <>
              <Button variant="secondary" onClick={() => setItemModal(false)}>
                Abbrechen
              </Button>
              <Button variant="primary" onClick={() => consumeItem(consumedItem)}>
                Verwenden
              </Button>
            </>
          ) : (
            <Button variant="success" onClick={() => (setItemModal(false), setRewardedItem({}))}>
              Sammeln
            </Button>
          )}
        </ModalFooter>
      </Modal>

      <style jsx="true">{`
        .inventory-container {
          padding: 15px;
        }
        .section-title {
          font-weight: 600;
          color: #333;
          border-bottom: 2px solid #eee;
          padding-bottom: 8px;
        }
        .category-btn {
          transition: all 0.2s ease;
        }
        .equipment-card {
          transition: all 0.2s ease;
          border: 1px solid rgba(0,0,0,0.125);
        }
        .equipment-card:hover {
          box-shadow: 0 0.25rem 0.75rem rgba(0,0,0,0.15);
        }
        .empty-slot {
          min-height: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .credits-card {
          background-color: #f8f9fa;
          border-left: 4px solid #28a745;
        }
        .inventory-divider {
          border-color: #dee2e6;
        }
        .list-header {
          background-color: #f8f9fa;
          font-weight: bold;
        }
        .list-item {
          transition: background-color 0.2s ease;
        }
        .list-item:hover {
          background-color: rgba(0,0,0,0.025);
        }
        .item-badges {
          margin-top: 5px;
        }
        .item-stats {
          color: #6c757d;
        }
        .empty-inventory {
          border: 2px dashed #dee2e6;
          border-radius: 8px;
          margin: 20px 0;
        }
        .empty-icon {
          font-size: 3rem;
          opacity: 0.5;
        }
        .reward-notification {
          animation: pulse 1.5s infinite;
        }
        
        /* Mobile optimizations */
        .sort-options {
          gap: 5px;
        }
        .pagination-container {
          background-color: #f8f9fa;
          border-radius: 4px;
        }
        .pagination {
          margin-bottom: 0;
        }
        
        @media (max-width: 767.98px) {
          .pagination .page-link {
            padding: 0.25rem 0.5rem;
          }
          .pagination-container {
            position: sticky;
            bottom: 0;
            z-index: 100;
            box-shadow: 0 -2px 10px rgba(0,0,0,0.1);
          }
        }
        
        @keyframes pulse {
          0% {
            transform: scale(1);
          }
          50% {
            transform: scale(1.05);
          }
          100% {
            transform: scale(1);
          }
        }
      `}</style>
    </div>
  );
};

export default Inventory;