import React, { useState, useEffect } from "react";
import axios from "axios";
import { GET } from "../tools/fetch";

const Bets = () => {
  const [sports, setSports] = useState([]);
  const [odds, setOdds] = useState([]);
  const [error, setError] = useState(null);
  const apiKey = "a0645ebd92dc0c89163e434421303735";
  const sportKey = "upcoming";
  const regions = "eu";
  const markets = "h2h";
  const oddsFormat = "decimal";
  const dateFormat = "iso";

  const fetchList = async () => {
    try {
      const response = await GET(
        `https://api.the-odds-api.com/v4/sports?apiKey=${apiKey}`
      );
      setSports(response);
    } catch (error) {}
  };
  useEffect(() => {
    // Fetch list of in-season sports
    fetchList();
    axios
      .get("https://api.the-odds-api.com/v4/sports", {
        params: {
          apiKey,
        },
      })
      .then((response) => {
        setSports(response.data);
      })
      .catch((error) => {
        setError(`Error fetching sports: ${error.response.status}`);
      });

    // Fetch live & upcoming games with odds
    axios
      .get(`https://api.the-odds-api.com/v4/sports/${sportKey}/odds`, {
        params: {
          apiKey,
          regions,
          markets,
          oddsFormat,
          dateFormat,
        },
      })
      .then((response) => {
        setOdds(response.data);
        console.log(
          "Remaining requests",
          response.headers["x-requests-remaining"]
        );
        console.log("Used requests", response.headers["x-requests-used"]);
      })
      .catch((error) => {
        setError(`Error fetching odds: ${error.response.status}`);
      });
  }, []);

  return (
    <div>
      <h1>Sports and Odds</h1>
      {error && <p>{error}</p>}
      <h2>In-Season Sports</h2>
      {sports.length === 0 ? (
        <p>Loading sports...</p>
      ) : (
        <ul>
          {sports.map((sport) => (
            <li key={sport.key}>{sport.title}</li>
          ))}
        </ul>
      )}
      <h2>Upcoming Games and Odds</h2>
      {odds.length === 0 ? (
        <p>Loading odds...</p>
      ) : (
        <ul>
          {odds.map((event) => (
            <li key={event.id}>
              <strong>{event.sport_title}</strong>: {event.commence_time}
              <ul>
                {event.bookmakers.map((bookmaker) => (
                  <li key={bookmaker.key}>
                    {bookmaker.title}
                    <ul>
                      {bookmaker.markets.map((market) => (
                        <li key={market.key}>
                          {market.key}:{" "}
                          {market.outcomes.map((outcome) => (
                            <span key={outcome.name}>
                              {outcome.name} ({outcome.price}){" "}
                            </span>
                          ))}
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Bets;
