import React, { useState, useEffect, useRef, useCallback } from "react";
import { ChevronUp, ChevronDown, Dices } from "lucide-react";
import { POST } from "../../../tools/fetch";
import { useSelector } from "react-redux";

// Enhanced Star Wars symbols data
const symbols = [
  { id: 0, name: "Rebel Alliance", icon: "☆", color: "#ff5757" },
  { id: 1, name: "Empire", icon: "✦", color: "#5d9cec" },
  { id: 2, name: "Lightsaber", icon: "†", color: "#72eb98" },
  { id: 3, name: "Death Star", icon: "◉", color: "#b0b0b0" },
  { id: 4, name: "Millennium Falcon", icon: "◈", color: "#f7d46a" },
  { id: 5, name: "X-Wing", icon: "⋉", color: "#ff876c" },
  { id: 6, name: "TIE Fighter", icon: "◊", color: "#97a1ff" },
  { id: 7, name: "Yoda", icon: "◬", color: "#8aeb72" },
];

// Payouts configuration
const payouts = [
  { combo: [0, 0, 0], multiplier: 10, name: "Three Rebels" },
  { combo: [1, 1, 1], multiplier: 10, name: "Three Empires" },
  { combo: [2, 2, 2], multiplier: 15, name: "Three Lightsabers" },
  { combo: [3, 3, 3], multiplier: 20, name: "Three Death Stars" },
  { combo: [4, 4, 4], multiplier: 30, name: "Three Falcons" },
  { combo: [5, 5, 5], multiplier: 25, name: "Three X-Wings" },
  { combo: [6, 6, 6], multiplier: 25, name: "Three TIE Fighters" },
  { combo: [7, 7, 7], multiplier: 50, name: "Three Yodas" },
];

// Improved Reel component with controlled final position and no jumping
const Reel = ({
  targetSymbol,
  spinning,
  spinDuration = 2000,
  spinDelay = 0,
}) => {
  const [displayedSymbols, setDisplayedSymbols] = useState([]);
  const positionRef = useRef(0);
  const animationRef = useRef(null);
  const stripRef = useRef([]);
  const spinStartTimeRef = useRef(0);
  const spinningRef = useRef(false);
  const lastResultRef = useRef(0);
  const symbolHeight = 64; // Height of each symbol in pixels
  const visibleSymbols = 5; // Number of visible symbols in the view

  // Initialize non-spinning state
  useEffect(() => {
    if (!spinning && !spinningRef.current) {
      initializeStaticDisplay(targetSymbol);
    }
    // Track the last target symbol shown
    if (targetSymbol !== undefined) {
      lastResultRef.current = targetSymbol;
    }
  }, [targetSymbol]);

  // Initialize static display with target symbol centered
  const initializeStaticDisplay = (symbol) => {
    const centerIdx = Math.floor(visibleSymbols / 2);
    const staticSymbols = [];

    for (let i = 0; i < visibleSymbols; i++) {
      let symbolIndex;
      // Center position should show target symbol
      if (i === centerIdx) {
        symbolIndex = symbol;
      } else {
        // Other positions show random symbols, but keep them consistent
        const distance = Math.abs(i - centerIdx);
        symbolIndex = (symbol + distance + i) % symbols.length;
      }

      staticSymbols.push({
        value: symbolIndex,
        offset: i * symbolHeight,
      });
    }

    setDisplayedSymbols(staticSymbols);
  };

  // Create the strip when spinning starts
  useEffect(() => {
    if (spinning && !spinningRef.current) {
      // Mark as spinning
      spinningRef.current = true;

      // Generate a longer strip for more convincing spin
      const stripLength = 100;
      const randomStrip = [];

      // Create the initial random portion of the strip
      for (let i = 0; i < stripLength - 10; i++) {
        randomStrip.push(Math.floor(Math.random() * symbols.length));
      }

      // Calculate the exact end position for the targeted symbol
      const centerIdx = Math.floor(visibleSymbols / 2);
      const targetPosition = stripLength - centerIdx - 1;

      // Create a carefully crafted ending sequence that guarantees the
      // right symbol will be displayed exactly in the center
      for (let i = 0; i < 10; i++) {
        const distanceFromEnd = 10 - i;
        // Last few positions should have target symbol repeated
        if (distanceFromEnd <= 3) {
          randomStrip.push(targetSymbol);
        } else {
          // Gradual transition to target symbol
          const transitionSymbol =
            (targetSymbol + distanceFromEnd) % symbols.length;
          randomStrip.push(transitionSymbol);
        }
      }

      stripRef.current = randomStrip;
      positionRef.current = 0;
      spinStartTimeRef.current = Date.now();

      // Start with the current display and gradually transition to the spinning strip
      updateVisibleSymbols(0);
    } else if (!spinning && spinningRef.current) {
      // Mark as not spinning anymore
      spinningRef.current = false;

      // Ensure we display the target result without any jumps
      // by using the last position from animation
      const finalPositionInCycle = positionRef.current % symbolHeight;
      const adjustedPosition = positionRef.current - finalPositionInCycle;

      // Finish the animation with a small smooth movement to the exact symbol position
      const smoothlyFinishSpin = () => {
        // We're targeting the exact center of the symbol
        initializeStaticDisplay(targetSymbol);
      };

      // Add a small delay to ensure visual continuity
      setTimeout(smoothlyFinishSpin, 50);
    }
  }, [spinning, targetSymbol]);

  // Update visible symbols based on current position
  const updateVisibleSymbols = useCallback(
    (currentPosition) => {
      const visibleSyms = [];
      const startIdx = Math.floor(currentPosition / symbolHeight);

      // Get symbols that should be visible at the current position
      for (let i = 0; i < visibleSymbols + 2; i++) {
        const idx = (startIdx + i) % stripRef.current.length;
        if (idx >= 0 && idx < stripRef.current.length) {
          visibleSyms.push({
            value: stripRef.current[idx],
            offset: (startIdx + i) * symbolHeight - currentPosition,
          });
        }
      }

      if (visibleSyms.length > 0) {
        setDisplayedSymbols(visibleSyms);
      }
    },
    [symbolHeight, visibleSymbols]
  );

  // Animation logic
  useEffect(() => {
    if (!spinning) return;

    // Clear any existing animation
    if (animationRef.current) {
      cancelAnimationFrame(animationRef.current);
    }

    // Maximum position - end of the strip
    const maxPosition = stripRef.current.length * symbolHeight;
    // Target position - where the reel should stop to show the target symbol
    const targetPosition =
      (stripRef.current.length - Math.floor(visibleSymbols / 2) - 1) *
      symbolHeight;

    // Animation function
    const animate = () => {
      const elapsed = Date.now() - spinStartTimeRef.current;

      // Determine current position based on a custom easing function
      if (elapsed < spinDelay) {
        // Not started spinning yet, wait for delay
        animationRef.current = requestAnimationFrame(animate);
        return;
      }

      const activeSpinTime = elapsed - spinDelay;

      if (activeSpinTime >= spinDuration) {
        // Spinning is done, set to final position without jumping
        positionRef.current = targetPosition;
        updateVisibleSymbols(targetPosition);
        return;
      }

      // Calculate progress (0 to 1)
      const progress = activeSpinTime / spinDuration;

      // Custom easing function with 3 phases:
      // - First 50% of time: Accelerate to max speed
      // - Next 30% of time: Maintain max speed
      // - Final 20% of time: Precise deceleration to stop exactly at target
      let currentPosition;

      if (progress < 0.5) {
        // Acceleration phase - ease-in quint
        const t = progress / 0.5;
        currentPosition = targetPosition * 0.6 * (t * t * t * t * t);
      } else if (progress < 0.8) {
        // Constant speed phase - linear with slight acceleration
        const t = (progress - 0.5) / 0.3;
        currentPosition = targetPosition * (0.6 + t * 0.2);
      } else {
        // Deceleration phase - precise targeting with easeOutQuint
        const t = (progress - 0.8) / 0.2;
        const tSq = 1 - t;
        currentPosition =
          targetPosition * (0.8 + 0.2 * (1 - tSq * tSq * tSq * tSq * tSq));
      }

      // Ensure we wrap around cleanly if needed
      while (currentPosition >= maxPosition) {
        currentPosition -= maxPosition - targetPosition;
      }

      positionRef.current = currentPosition;
      updateVisibleSymbols(currentPosition);
      animationRef.current = requestAnimationFrame(animate);
    };

    // Start animation
    animationRef.current = requestAnimationFrame(animate);

    // Cleanup
    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [spinning, spinDelay, spinDuration, updateVisibleSymbols]);

  return (
    <div className="w-20 sm:w-24 md:w-28 h-72 sm:h-80 overflow-hidden bg-gray-800 rounded-lg border-2 border-yellow-500 relative">
      {/* Five equal rows with borders */}
      <div className="grid grid-rows-5 h-full w-full">
        <div className="border-b border-gray-700/50"></div>
        <div className="border-b border-gray-700/50"></div>
        <div className="border-b border-gray-700/50 bg-gray-700"></div>
        <div className="border-b border-gray-700/50"></div>
        <div></div>
      </div>

      {/* Highlight for the center symbol (winning position) */}
      <div className="absolute top-1/2 left-0 right-0 h-16 transform -translate-y-1/2 border-y border-yellow-500 z-0"></div>

      {/* Symbols strip */}
      <div className="absolute top-0 left-0 w-full h-full">
        {displayedSymbols.map((symbol, index) => (
          <div
            key={index}
            className="absolute w-full h-16 flex items-center justify-center transition-transform z-20"
            style={{
              transform: `translateY(${symbol.offset}px)`,
              filter: spinning ? "blur(1px)" : "none",
            }}
          >
            <div
              className="text-3xl sm:text-4xl md:text-5xl"
              style={{
                color: symbols[symbol.value].color,
                textShadow: `0 0 10px ${symbols[symbol.value].color}`,
                fontWeight: "bold",
                transition: "transform 0.2s ease",
              }}
            >
              {symbols[symbol.value].icon}
            </div>
          </div>
        ))}
      </div>

      {/* Top and bottom fade gradients */}
      <div className="absolute top-0 left-0 right-0 h-12 bg-gradient-to-b from-gray-800 to-transparent pointer-events-none z-10"></div>
      <div className="absolute bottom-0 left-0 right-0 h-12 bg-gradient-to-t from-gray-800 to-transparent pointer-events-none z-10"></div>
    </div>
  );
};

// Payout table component
const PayoutTable = () => (
  <div className="bg-gray-800 p-3 sm:p-4 rounded-lg mt-4 sm:mt-6 border border-yellow-500/50">
    <h3 className="text-lg sm:text-xl text-yellow-400 mb-1 sm:mb-2 font-starwars">
      Payouts
    </h3>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-1 sm:gap-2">
      {payouts.map((payout, index) => (
        <div
          key={index}
          className="flex justify-between items-center border-b border-gray-700 py-1"
        >
          <div className="flex items-center">
            <span
              className="text-base sm:text-lg mr-1 sm:mr-2"
              style={{ color: symbols[payout.combo[0]].color }}
            >
              {symbols[payout.combo[0]].icon}
            </span>
            <span className="text-xs sm:text-sm md:text-base whitespace-nowrap">
              {payout.name}
            </span>
          </div>
          <span className="text-yellow-400 text-xs sm:text-sm md:text-base ml-2">
            x{payout.multiplier}
          </span>
        </div>
      ))}
    </div>
  </div>
);

// Main slot game component
const Slot2 = () => {
  const skills = useSelector((state) => state.skills.skills);
  const userData = useSelector((state) => state.user);

  const [spinning, setSpinning] = useState(false);
  const [reelResults, setReelResults] = useState([0, 1, 2]);
  const [nextReelResults, setNextReelResults] = useState(null);
  const [credits, setCredits] = useState(skills?.char?.cash || 1000);
  const [bet, setBet] = useState(10);
  const [lastWin, setLastWin] = useState(0);
  const [message, setMessage] = useState("");
  const [winEffect, setWinEffect] = useState(false);
  const [isLoadingResults, setIsLoadingResults] = useState(false);

  // Store pending response for when animation completes
  const pendingResponseRef = useRef(null);

  // Update reels with pending results when spinning stops
  useEffect(() => {
    if (!spinning && pendingResponseRef.current) {
      // Animation has finished, now update all the state
      const response = pendingResponseRef.current;

      setCredits(response.newBalance);
      setLastWin(response.winnings);

      // Show win message
      if (response.winnings > 0) {
        setMessage(`You won ${response.winnings} credits!`);
        setWinEffect(true);

        // For big wins, show special message
        if (response.winnings >= bet * 20) {
          setTimeout(() => {
            setMessage(`JACKPOT! ${response.winnings} credits!`);
          }, 500);
        }
      } else {
        setMessage("Try again!");
      }

      // Clear the pending response
      pendingResponseRef.current = null;
    }
  }, [spinning, bet]);

  // Spin handler
  const handleSpin = async () => {
    if (spinning || credits < bet || isLoadingResults) return;

    // Reset state
    setMessage("");
    setWinEffect(false);
    setIsLoadingResults(true);

    try {
      // Get the results from the backend BEFORE starting animation
      const response = await POST("/roulette/slotSpin", {
        userId: userData.userId,
        bet: bet,
      });

      // Store the response for when animation completes
      pendingResponseRef.current = response;

      // Now that we have the results, update the target symbols
      setIsLoadingResults(false);

      // Update the reel results that the reels will animate to
      setReelResults(response.reels);

      // Start spinning animation
      setSpinning(true);

      // Set a timeout to end the spinning state
      setTimeout(() => {
        setSpinning(false);
      }, 3000); // Animation duration
    } catch (error) {
      setMessage(error.message || "An error occurred");
      setSpinning(false);
      setIsLoadingResults(false);
      pendingResponseRef.current = null;
    }
  };

  // Bet controls
  const increaseBet = () => bet < 100 && setBet(bet + 10);
  const decreaseBet = () => bet > 10 && setBet(bet - 10);
  const maxBet = () => setBet(100);

  return (
    <div
      className={`bg-gray-900 text-white p-3 sm:p-6 max-w-4xl mx-auto rounded-xl border ${
        winEffect
          ? "border-yellow-300 shadow-2xl shadow-yellow-400/30"
          : "border-yellow-400 shadow-lg shadow-yellow-400/20"
      }`}
    >
      <h1 className="text-2xl sm:text-3xl font-bold text-center text-yellow-400 mb-4 sm:mb-6">
        Star Wars Slot Machine
      </h1>

      <div className="flex flex-col lg:flex-row gap-4 sm:gap-6">
        <div className="flex-1">
          {/* Slot Machine */}
          <div
            className={`flex justify-center space-x-2 sm:space-x-4 bg-gray-800 p-3 sm:p-6 rounded-lg border ${
              winEffect ? "border-yellow-300" : "border-yellow-500"
            }`}
          >
            {reelResults.map((symbolId, index) => (
              <Reel
                key={index}
                targetSymbol={symbolId}
                spinning={spinning}
                spinDuration={2000 + index * 200} // Slightly different duration for each reel
                spinDelay={index * 400} // Staggered start for each reel
              />
            ))}
          </div>

          {/* Message Area */}
          {message && (
            <div
              className={`text-center mt-4 p-2 ${
                winEffect
                  ? "bg-yellow-400/20 text-yellow-300 font-bold"
                  : "bg-yellow-400/10 text-yellow-400"
              } rounded`}
            >
              {message}
            </div>
          )}

          {/* Game Info */}
          <div className="flex justify-between mt-4 text-base sm:text-xl">
            <div>
              Credits: <span className="text-yellow-400">{credits}</span>
            </div>
            <div>
              Last Win:{" "}
              <span
                className={`${
                  winEffect ? "text-yellow-300 font-bold" : "text-green-400"
                }`}
              >
                {lastWin}
              </span>
            </div>
          </div>

          {/* Controls */}
          <div className="mt-4 sm:mt-6 flex flex-col items-center">
            <div className="flex flex-wrap justify-center items-center gap-2 sm:gap-4 mb-3 sm:mb-4">
              <div className="flex items-center gap-1 sm:gap-2">
                <button
                  onClick={decreaseBet}
                  disabled={spinning || bet <= 10}
                  className="p-1 sm:p-2 bg-gray-700 rounded-full hover:bg-gray-600 disabled:opacity-50"
                >
                  <ChevronDown size={16} className="sm:hidden" />
                  <ChevronDown size={20} className="hidden sm:block" />
                </button>
                <div className="px-2 sm:px-4 py-1 sm:py-2 bg-gray-800 rounded text-base sm:text-xl min-w-16 sm:min-w-24 text-center">
                  Bet: {bet}
                </div>
                <button
                  onClick={increaseBet}
                  disabled={spinning || bet >= 100}
                  className="p-1 sm:p-2 bg-gray-700 rounded-full hover:bg-gray-600 disabled:opacity-50"
                >
                  <ChevronUp size={16} className="sm:hidden" />
                  <ChevronUp size={20} className="hidden sm:block" />
                </button>
              </div>

              <button
                onClick={maxBet}
                disabled={spinning || bet >= 100}
                className="px-2 sm:px-3 py-1 sm:py-2 bg-yellow-700 rounded hover:bg-yellow-600 disabled:opacity-50 text-sm sm:text-base"
              >
                Max Bet
              </button>
            </div>

            <button
              onClick={handleSpin}
              disabled={spinning || credits < bet || isLoadingResults}
              className={`flex items-center justify-center gap-1 sm:gap-2 w-36 sm:w-48 py-3 sm:py-4 ${
                spinning || isLoadingResults
                  ? "bg-gray-700"
                  : "bg-red-600 hover:bg-red-500"
              } text-white text-lg sm:text-xl font-bold rounded-full transition-colors disabled:opacity-50 disabled:bg-gray-700`}
            >
              <Dices size={20} className="sm:hidden" />
              <Dices size={24} className="hidden sm:block" />
              {isLoadingResults
                ? "LOADING..."
                : spinning
                ? "SPINNING..."
                : "SPIN"}
            </button>
          </div>
        </div>

        <div className="lg:w-64">
          <PayoutTable />
        </div>
      </div>
    </div>
  );
};

export default Slot2;
