import React, { useEffect, useState } from "react";
import { GET } from "../../tools/fetch";
import { Col, Container, Row } from "react-bootstrap";
import SearchIcon from "@mui/icons-material/Search";
import SummarizeIcon from "@mui/icons-material/SummarizeOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import { useHistory } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";

const Reports = () => {
  const [loading, setLoading] = useState();
  const [reports, setReports] = useState();
  const history = useHistory();

  const loadReports = async () => {
    try {
      setLoading(true);
      const request = await GET("/messages/reports");
      if (request) {
        setReports(request);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleView = (id) => {
    history.push(`/reports/${id}`);
  };

  const handleDelete = async (id) => {
    await GET(`/messages/deletereport/${id}`);
    await loadReports();
  };

  useEffect(() => {
    loadReports();
  }, []);

  return (
    <div>
      <Container>
        <Row className="bg-secondary">
          <Col md="3">Berichte</Col>
          <Col>Erkundungen</Col>
        </Row>

        <Row>
          <Col md="3">
            <div>
              <SearchIcon /> Erkundungen
            </div>
          </Col>
          <Col>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Time</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                {loading === false &&
                  reports.numbers.map((explore, id) => {
                    const filteredReports = reports.reports.filter(
                      (report) => report.explore_id === explore.explore_id
                    );
                    const lastReport = filteredReports.pop();
                    const moment_temp = moment(lastReport.end, "X");

                    return (
                      <TableBody key={id}>
                        <TableRow hover>
                          <TableCell>{id + 1}</TableCell>
                          <TableCell>{moment_temp.fromNow()}</TableCell>
                          <TableCell>
                            <SummarizeIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => handleView(explore.explore_id)}
                            />{" "}
                            <DeleteIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => handleDelete(explore.explore_id)}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    );
                  })}
              </Table>
            </TableContainer>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Reports;
