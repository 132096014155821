import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GET } from "../../tools/fetch";
import { Card, Col, Row } from "react-bootstrap";
import moment from "moment";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import { useHistory } from "react-router-dom";
const ViewReport = () => {
  const id = useParams();
  const [loading, setLoading] = useState();
  const [report, setReport] = useState();
  const history = useHistory();
  const loadReport = async () => {
    try {
      setLoading(true);
      const request = await GET(`/messages/viewreport/${id.id}`);
      setReport(request.report);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const formatTime = (timestamp) => {
    const hours = Math.floor(timestamp / 3600);
    const minutes = Math.floor((timestamp % 3600) / 60);
    return `${hours < 10 ? "0" : ""}${hours}:${
      minutes < 10 ? "0" : ""
    }${minutes}`;
  };

  useEffect(() => {
    loadReport();
  }, []);

  return (
    <div>
      <div
        className="p-2 container"
        style={{
          backgroundImage:
            "url(" + require("../../images/city/explore.png") + ")",
          marginLeft: "-15px",
          marginRight: "-15px",
          maxWidth: "1024px",
          margin: "0 auto",
          maxHeight: "1024px",
        }}
      >
        {loading === false && (
          <Card className="shadow-lg" style={{ backgroundColor: "#ffffffc2" }}>
            <Card.Body>
              <Card.Title>
                <div className="d-flex justify-content-between">
                  <div>
                    <KeyboardBackspaceOutlinedIcon
                      className="border border-secondary rounded"
                      style={{ cursor: "pointer" }}
                      onClick={() => history.goBack()}
                    />{" "}
                    Bericht
                  </div>
                  <div>
                    <small>
                      {moment(report[0].time, "X").format("DD.MM.Y")}
                    </small>
                  </div>
                </div>
                <hr />
              </Card.Title>
              <div
                style={{
                  maxHeight: "770px",
                  overflowY: "scroll",
                  overflowX: "clip",
                }}
              >
                {loading === false &&
                  report.map((log, id) => {
                    return (
                      <Row key={id}>
                        <Col md="auto">
                          {formatTime(log.time - report[0].time)}
                        </Col>
                        <Col>
                          {log.type == "Händler2" ? (
                            <div>
                              {log.text.name}
                              {log.text.mindmg > 0 && `, ${log.text.mindmg}`}
                              {log.text.mindmg > 0 && ` - ${log.text.maxdmg}`}
                              {log.text.stat1 !== "" && `, ${log.text.stat1}`}
                              {log.text.stat2 !== "" && `, ${log.text.stat2}`}
                              {log.text.stat3 !== "" && `, ${log.text.stat3}`}
                            </div>
                          ) : (
                            <div>
                              {log.text}{" "}
                              {log.fight !== "" && (
                                <a
                                  target="_blank"
                                  href={`https://react.starwarslegends.de/rest-api/fight/reada?id=${log.fight}`}
                                >
                                  [Kampfbericht]
                                </a>
                              )}
                            </div>
                          )}
                        </Col>
                      </Row>
                    );
                  })}
              </div>
            </Card.Body>
          </Card>
        )}
        {/* für später wenn mal mehr charaktere dazu kommen}
      <Card className="shadow-lg" style={{ backgroundColor: "#ffffffc2" }}>
        <Card.Body>
          <Card.Title>
            Charaktere
            <hr />
          </Card.Title>
          <div className="d-flex">
            {loading === false &&
              exploreData.explore.members.map((member, id) => {
                return (
                  <UserCard
                    HealthPro={member.HealthPro}
                    ManaPro={member.ManaPro}
                    name={member.username}
                    level={member.skills.level}
                  />
                );
              })}
          </div>
        </Card.Body>
            </Card>{*/}
      </div>
    </div>
  );
};

export default ViewReport;
