import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GET } from "../../tools/fetch";
import { fetchAllianceData } from "../../redux/actions/allianceActions";
import { characterState__setOverviewData } from "../../redux/actions/characterActions";
import { firestore } from "../../tools/firebase";
import { useLocation } from "react-router-dom";

const Checks = ({ children, roles, ...props }) => {
  const userId = useSelector((state) => state.user.userId);
  const username = useSelector((state) => state.user.username);
  const userid = useSelector((state) => state.user.userId);
  const [offline, setOffline] = useState(true);

  const dispatch = useDispatch();

  const checkOffline = async () => {
    try {
      const request = await GET("/admin/offline");
      if (request.status.status === 1) {
        setOffline(true);
        dispatch({ type: "SET_OFFLINE", payload: true });
      } else {
        setOffline(false);
      }
    } catch (error) {}
  };
  const checkIP = async () => {
    try {
      await GET("/accounts/checkIP");
    } catch (error) {}
  };
  const checkTrades = async () => {
    try {
      const requestsDB = await GET("/trade/requests");
      if (requestsDB.requests.length > 0) {
        if (
          requestsDB.requests[0].offerer !== userId &&
          requestsDB.requests[0].status !== 3
        ) {
          dispatch({ type: "NEW_TRADE", payload: true });
        } else {
          dispatch({ type: "NEW_TRADE", payload: false });
        }
      }
    } catch (error) {
    } finally {
    }
  };
  const checkRaids = async () => {
    try {
      const responseAlli = await GET("/alliances");
      if (responseAlli) {
        dispatch(fetchAllianceData(responseAlli));
      }
    } catch (error) {
    } finally {
    }
  };
  const checkPM = () => {
    if (username) {
      const museums = firestore
        .collectionGroup("messages")
        .where("seen", "==", 0)
        .where("receiver", "==", username);
      museums.get().then((querySnapshot) => {
        if (querySnapshot.size > 0) {
          dispatch({ type: "NEW_MESSAGE", payload: true });
        } else {
          dispatch({ type: "NEW_MESSAGE", payload: false });
        }
      });
      return () => {
        museums();
      };
    }
  };
  const loadData = async () => {
    try {
      const response = await GET("/character/overview");
      if (response) {
        dispatch(characterState__setOverviewData(response));
      }
    } catch (e) {
      return;
    }
  };
  const checkReport = async () => {
    try {
      const request = await GET("/messages/reports");
      if (request.unread > 0) {
        dispatch({ type: "NEW_REPORT", payload: true });
      }
    } catch (error) {}
  };

  let location = useLocation();
  checkOffline();
  if (offline !== true) {
    checkIP();

    if (!location.pathname.includes("raid")) {
      //offene raids
      checkRaids();
    }
    //für Skillpoints
    loadData();
    //PM
    checkPM();
    //Report
    checkReport();
    //Tradeoffers
    checkTrades();
  }
  return <div>{children}</div>;
};

export default Checks;
