import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { POST } from "../../../tools/fetch";
import spinButtonImage from "./images/spin-circle.png";
import "./roulette.css";

function Roulette() {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);
  const userId = userData.userId;
  const skills = useSelector((state) => state.skills.skills);

  const [isSpinning, setIsSpinning] = useState(false);
  const [spinResult, setSpinResult] = useState(null);
  const [selectedChip, setSelectedChip] = useState(5);
  const [playerBalance, setPlayerBalance] = useState(
    skills?.char?.cash || 1000
  );
  const [betAmount, setBetAmount] = useState(0);
  const [bets, setBets] = useState([]);
  const [previousNumbers, setPreviousNumbers] = useState([]);
  const [message, setMessage] = useState("");

  // European roulette numbers - correctly ordered for the wheel
  const wheelNumbers = [
    0, 32, 15, 19, 4, 21, 2, 25, 17, 34, 6, 27, 13, 36, 11, 30, 8, 23, 10, 5,
    24, 16, 33, 1, 20, 14, 31, 9, 22, 18, 29, 7, 28, 12, 35, 3, 26,
  ];

  // Define red numbers
  const redNumbers = [
    1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36,
  ];

  // Chip values in Star Wars credits
  const chipValues = [1, 5, 25, 100, 500];

  // Mapping of bet types to payout ratios
  const payoutRatios = {
    straight: 35, // Single number
    split: 17, // Two adjacent numbers
    street: 11, // Three numbers in a horizontal line
    corner: 8, // Four numbers that form a square
    double_street: 5, // Six numbers from two adjacent streets
    column: 2, // 12 numbers in a column
    dozen: 2, // 1-12, 13-24, 25-36
    red: 1, // Red numbers
    black: 1, // Black numbers
    even: 1, // Even numbers
    odd: 1, // Odd numbers
    low: 1, // Numbers 1-18
    high: 1, // Numbers 19-36
  };

  useEffect(() => {
    if (skills?.char?.cash) {
      setPlayerBalance(skills.char.cash);
    }
  }, [skills]);

  function getRandomOffset() {
    // Generate a small random offset between -5 and 5 degrees
    return (Math.random() * 10 - 5).toFixed(2);
  }

  // Function to check if a bet exists for a given number
  const getBetOnNumber = (number) => {
    return bets.find(
      (bet) => bet.type === "straight" && bet.numbers.includes(number)
    );
  };

  // Function to get total bet amount on a specific number
  const getBetAmountOnNumber = (number) => {
    const bet = getBetOnNumber(number);
    return bet ? bet.amount : 0;
  };

  // Function to check if a bet exists for a given bet type
  const getBetForType = (betType, betNumbers) => {
    return bets.find(
      (bet) =>
        bet.type === betType &&
        JSON.stringify(bet.numbers) === JSON.stringify(betNumbers)
    );
  };

  // Function to get bet amount for a specific bet type
  const getBetAmountForType = (betType, betNumbers) => {
    const bet = getBetForType(betType, betNumbers);
    return bet ? bet.amount : 0;
  };

  const placeBet = (betType, numbers, amount) => {
    if (isSpinning) return;

    if (amount > playerBalance) {
      setMessage("Insufficient credits for bet");
      setTimeout(() => setMessage(""), 3000);
      return;
    }

    // Check if bet already exists
    const existingBetIndex = bets.findIndex(
      (bet) =>
        bet.type === betType &&
        JSON.stringify(bet.numbers) === JSON.stringify(numbers)
    );

    if (existingBetIndex !== -1) {
      // Update existing bet
      const updatedBets = [...bets];
      updatedBets[existingBetIndex].amount += amount;
      setBets(updatedBets);
    } else {
      // Add new bet
      setBets([...bets, { type: betType, numbers, amount }]);
    }

    setBetAmount(betAmount + amount);
    setPlayerBalance(playerBalance - amount);
  };

  const clearBets = () => {
    if (isSpinning) return;
    setPlayerBalance(playerBalance + betAmount);
    setBets([]);
    setBetAmount(0);
  };

  const spin = async () => {
    if (betAmount === 0) {
      setMessage("Please place a bet first");
      setTimeout(() => setMessage(""), 3000);
      return;
    }

    setIsSpinning(true);
    setMessage("Spinning...");

    try {
      // Store current bets for animation
      const currentBets = [...bets];

      // Call backend to get result and update player balance
      const response = await POST("/roulette/spin", {
        userId,
        bets: bets.map((bet) => ({
          type: bet.type,
          numbers: bet.numbers,
          amount: bet.amount,
        })),
      });

      // Clear bets immediately so they don't show during animation
      setBets([]);
      setBetAmount(0);

      // Calculate the precise ball landing position for the result
      const result = response.result;
      const finalRotation = calculateExactBallPosition(result);

      // Set the CSS variable for the final rotation
      document.documentElement.style.setProperty(
        "--final-rotation",
        `${finalRotation}deg`
      );

      // Wait for wheel animation
      setTimeout(() => {
        setSpinResult(result);

        // Update player balance after the result is shown
        setTimeout(() => {
          if (response.winnings > 0) {
            setMessage(`You won ${response.winnings} credits!`);
          } else {
            setMessage("Better luck next time!");
          }

          setPreviousNumbers([result, ...previousNumbers].slice(0, 10));
          setIsSpinning(false);

          // Set player balance directly from server response
          setPlayerBalance(response.newBalance);

          // Update Redux store with the new balance
          dispatch({
            type: "UPDATE_PLAYER_CREDITS",
            payload: response.newBalance,
          });
        }, 1000);
      }, 5000); // 5 seconds for wheel animation
    } catch (error) {
      console.error("Error spinning roulette:", error);
      setMessage("An error occurred. Please try again.");
      setIsSpinning(false);

      // If there was an error, restore the balance
      setPlayerBalance((prevBalance) => prevBalance + betAmount);
      setBets([]);
      setBetAmount(0);
    }
  };

  // Generate column numbers (for 2:1 bets)
  const getColumnNumbers = (col) => {
    // Column 1 (right): 3,6,9,12,15,18,21,24,27,30,33,36
    // Column 2 (middle): 2,5,8,11,14,17,20,23,26,29,32,35
    // Column 3 (left): 1,4,7,10,13,16,19,22,25,28,31,34
    if (col === 1) {
      return [3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36];
    } else if (col === 2) {
      return [2, 5, 8, 11, 14, 17, 20, 23, 26, 29, 32, 35];
    } else if (col === 3) {
      return [1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34];
    }
    return [];
  };

  // Generate numbers for dozens bet
  const getDozenNumbers = (dozen) => {
    const start = (dozen - 1) * 12 + 1;
    return Array.from({ length: 12 }, (_, i) => i + start);
  };

  const calculateExactBallPosition = (resultNumber) => {
    // Find the index of the result number in the wheel
    const resultIndex = wheelNumbers.findIndex((num) => num === resultNumber);
    if (resultIndex === -1) return 0;

    // Calculate the exact angle each number takes up on the wheel
    const anglePerNumber = 360 / wheelNumbers.length;

    // Calculate the exact rotation needed for this number
    // The rotation is negative because we're going counter-clockwise
    // The rotation also needs to account for the wheel's clockwise rotation
    const resultAngle = resultIndex * anglePerNumber;

    return resultAngle;
  };

  return (
    <div className="roulette-container">
      <div className="roulette-header">
        <div className="player-info">
          <h3>
            Credits: <span className="credit-amount">{playerBalance}</span>
          </h3>
          <h3>
            Current Bet: <span className="bet-amount">{betAmount}</span>
          </h3>
        </div>
        <div className="message-area">{message}</div>
        <div className="previous-numbers">
          <span className="prev-numbers-label">Previous Numbers:</span>
          {previousNumbers.map((number, index) => (
            <span
              key={index}
              className={`prev-number ${
                redNumbers.includes(number)
                  ? "red"
                  : number === 0
                  ? "green"
                  : "black"
              }`}
            >
              {number}
            </span>
          ))}
        </div>
      </div>

      <div className="roulette-table">
        <div className="wheel-container">
          <div className={`roulette-wheel ${isSpinning ? "spinning" : ""}`}>
            {/* Outer numbers ring */}
            {wheelNumbers.map((number, index) => (
              <div
                key={index}
                className={`wheel-number ${
                  redNumbers.includes(number)
                    ? "red"
                    : number === 0
                    ? "green"
                    : "black"
                }`}
                style={{
                  transform: `rotate(${
                    index * (360 / wheelNumbers.length)
                  }deg)`,
                }}
              >
                {number}
              </div>
            ))}

            {/* Center spin button - FIXED TEXT */}
            <div
              className="wheel-center"
              onClick={!isSpinning ? spin : undefined}
            >
              Spin
            </div>
          </div>

          {/* Ball track with visible ball */}
          <div className={`ball-track ${isSpinning ? "spinning" : ""}`}>
            <div className="ball"></div>
          </div>
        </div>

        <div className="betting-board">
          {/* Corrected layout with numbers grid and 2:1 on the right */}
          <div className="board-layout">
            {/* Green zero on the left */}
            <div className="zero-section">
              <div
                className={`number-zero ${getBetOnNumber(0) ? "has-bet" : ""}`}
                onClick={() => placeBet("straight", [0], selectedChip)}
              >
                0
                {getBetAmountOnNumber(0) > 0 && (
                  <div className="placed-chip">{getBetAmountOnNumber(0)}</div>
                )}
              </div>
            </div>

            {/* Numbers grid - ordered based on standard roulette layout */}
            <div className="numbers-grid">
              {/* Top row: 3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36 */}
              <div className="number-row">
                {[3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36].map((number) => (
                  <div
                    key={number}
                    className={`number-block ${
                      redNumbers.includes(number) ? "red" : "black"
                    } ${getBetOnNumber(number) ? "has-bet" : ""}`}
                    onClick={() => placeBet("straight", [number], selectedChip)}
                  >
                    {number}
                    {getBetAmountOnNumber(number) > 0 && (
                      <div className="placed-chip">
                        {getBetAmountOnNumber(number)}
                      </div>
                    )}
                  </div>
                ))}
              </div>

              {/* Middle row: 2, 5, 8, 11, 14, 17, 20, 23, 26, 29, 32, 35 */}
              <div className="number-row">
                {[2, 5, 8, 11, 14, 17, 20, 23, 26, 29, 32, 35].map((number) => (
                  <div
                    key={number}
                    className={`number-block ${
                      redNumbers.includes(number) ? "red" : "black"
                    } ${getBetOnNumber(number) ? "has-bet" : ""}`}
                    onClick={() => placeBet("straight", [number], selectedChip)}
                  >
                    {number}
                    {getBetAmountOnNumber(number) > 0 && (
                      <div className="placed-chip">
                        {getBetAmountOnNumber(number)}
                      </div>
                    )}
                  </div>
                ))}
              </div>

              {/* Bottom row: 1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34 */}
              <div className="number-row">
                {[1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34].map((number) => (
                  <div
                    key={number}
                    className={`number-block ${
                      redNumbers.includes(number) ? "red" : "black"
                    } ${getBetOnNumber(number) ? "has-bet" : ""}`}
                    onClick={() => placeBet("straight", [number], selectedChip)}
                  >
                    {number}
                    {getBetAmountOnNumber(number) > 0 && (
                      <div className="placed-chip">
                        {getBetAmountOnNumber(number)}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>

            {/* 2:1 betting options on the right side */}
            <div className="columns">
              {[1, 2, 3].map((col) => {
                const columnNumbers = getColumnNumbers(col);
                const betAmount = getBetAmountForType("column", columnNumbers);
                return (
                  <div
                    key={col}
                    className={`column-bet ${betAmount > 0 ? "has-bet" : ""}`}
                    onClick={() =>
                      placeBet("column", columnNumbers, selectedChip)
                    }
                  >
                    2:1
                    {betAmount > 0 && (
                      <div className="placed-chip">{betAmount}</div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>

          <div className="outside-bets">
            <div className="dozens">
              {[1, 2, 3].map((dozen) => {
                const dozenNumbers = getDozenNumbers(dozen);
                const betAmount = getBetAmountForType("dozen", dozenNumbers);
                return (
                  <div
                    key={dozen}
                    className={`dozen-bet ${betAmount > 0 ? "has-bet" : ""}`}
                    onClick={() =>
                      placeBet("dozen", dozenNumbers, selectedChip)
                    }
                  >
                    {dozen === 1 ? "1st 12" : dozen === 2 ? "2nd 12" : "3rd 12"}
                    {betAmount > 0 && (
                      <div className="placed-chip">{betAmount}</div>
                    )}
                  </div>
                );
              })}
            </div>

            <div className="even-money-bets">
              {[
                {
                  type: "low",
                  label: "1-18",
                  numbers: Array.from({ length: 18 }, (_, i) => i + 1),
                },
                {
                  type: "even",
                  label: "EVEN",
                  numbers: Array.from({ length: 18 }, (_, i) => (i + 1) * 2),
                },
                {
                  type: "red",
                  label: "RED",
                  numbers: redNumbers,
                  className: "red",
                },
                {
                  type: "black",
                  label: "BLACK",
                  numbers: wheelNumbers.filter(
                    (n) => n !== 0 && !redNumbers.includes(n)
                  ),
                  className: "black",
                },
                {
                  type: "odd",
                  label: "ODD",
                  numbers: Array.from({ length: 18 }, (_, i) => i * 2 + 1),
                },
                {
                  type: "high",
                  label: "19-36",
                  numbers: Array.from({ length: 18 }, (_, i) => i + 19),
                },
              ].map((bet, index) => {
                const betAmount = getBetAmountForType(bet.type, bet.numbers);
                return (
                  <div
                    key={index}
                    className={`even-money-bet ${bet.className || ""} ${
                      betAmount > 0 ? "has-bet" : ""
                    }`}
                    onClick={() =>
                      placeBet(bet.type, bet.numbers, selectedChip)
                    }
                  >
                    {bet.label}
                    {betAmount > 0 && (
                      <div className="placed-chip">{betAmount}</div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="chips-container">
        {chipValues.map((value) => (
          <div
            key={value}
            className={`chip chip-${value} ${
              selectedChip === value ? "selected" : ""
            }`}
            onClick={() => setSelectedChip(value)}
          >
            {value}
          </div>
        ))}
        <button className="clear-bets-button" onClick={clearBets}>
          Clear Bets
        </button>
      </div>
    </div>
  );
}

export default Roulette;
